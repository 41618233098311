/* eslint-disable operator-linebreak */
export const CHANGE_THEME = 'CHANGE_THEME';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET';
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';

// MEDIA
export const ADD_MEDIA = 'ADD_MEDIA';
export const FETCH_MEDIA = 'FETCH_MEDIA';
export const DELETE_MEDIA = 'DELETE_MEDIA';

// RESOURCE
export const ADD_RESOURCE = 'ADD_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const FETCH_RESOURCE = 'FETCH_RESOURCE';
export const FETCH_RESOURCES = 'FETCH_RESOURCES';
export const FETCH_RESOURCE_MEDIA = 'FETCH_RESOURCE_MEDIA';

// COUNTRY STATE
export const FETCH_COUNTRY_STATE = 'FETCH_COUNTRY_STATE';

// COMMENT
export const FETCH_COMMENTS = 'FETCH_COMMENTS';

// PROGRAM
export const GET_ACTIVE_PROGRAM = 'GET_ACTIVE_PROGRAM';
export const GET_ACTIVE_PROGRAM_WITH_ASSOCIATE =
  'GET_ACTIVE_PROGRAM_WITH_ASSOCIATE';
export const GET_PROGRAM_BY_ID = 'GET_PROGRAM_BY_ID';
