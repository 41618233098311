import { ActionDispatch } from '../../@types/apiRes';
import {
  ADD_RESOURCE,
  UPDATE_RESOURCE,
  FETCH_RESOURCE,
  FETCH_RESOURCES,
  LOGOUT_USER,
  FETCH_RESOURCE_MEDIA,
} from '../constant';
import resources from '../initialization/resources';

const resourcesReducer = (state: any, action: ActionDispatch) => {
  switch (action.type) {
    case ADD_RESOURCE:
      return {
        ...state,
        addResource: action.payload,
      };
    case UPDATE_RESOURCE:
      return {
        ...state,
        updateResource: action.payload,
      };
    case FETCH_RESOURCE:
      return {
        ...state,
        fetchResource: action.payload,
      };
    case FETCH_RESOURCES:
      return {
        ...state,
        fetchResources: action.payload,
      };
    case FETCH_RESOURCE_MEDIA:
      return {
        ...state,
        fetchResourceMedia: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        ...resources,
      };
    default:
      return state;
  }
};
export default resourcesReducer;
