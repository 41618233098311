import { ActionDispatch } from '../../@types/apiRes';
import {
  ADD_MEDIA,
  FETCH_MEDIA,
  LOGOUT_USER,
} from '../constant';
import media from '../initialization/media';

const mediaReducer = (state: any, action: ActionDispatch) => {
  switch (action.type) {
    case ADD_MEDIA:
      return {
        ...state,
        addMedia: action.payload,
      };
    case FETCH_MEDIA:
      return {
        ...state,
        fetchMedia: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        ...media,
      };
    default:
      return state;
  }
};
export default mediaReducer;
