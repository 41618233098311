import { ActionDispatch } from '../../@types/apiRes';
import { FETCH_COUNTRY_STATE, LOGOUT_USER } from '../constant';
import countryState from '../initialization/country-state';

const countryStateReducer = (state: any, action: ActionDispatch) => {
  switch (action.type) {
    case FETCH_COUNTRY_STATE:
      return {
        ...state,
        fetchState: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        ...countryState,
      };
    default:
      return state;
  }
};
export default countryStateReducer;
