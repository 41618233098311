/* eslint-disable object-curly-newline */
import { ActionDispatch } from '../../@types/apiRes';
import authReducer from './auth';
import mediaReducer from './media';
import resourcesReducer from './resources';
import countryStateReducer from './country-state';
import commentReducer from './comment';
import programReducer from './program';

const reducer = (
  { resources, auth, media, countryState, comment, program }: any,
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  action: ActionDispatch
) => ({
  resources: resourcesReducer(resources, action),
  comment: commentReducer(comment, action),
  auth: authReducer(auth, action),
  media: mediaReducer(media, action),
  countryState: countryStateReducer(countryState, action),
  program: programReducer(program, action),
});

export default reducer;
