import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import initialization from 'redux/initialization';
import reducer from 'redux/reducers';
import { StateProvider } from 'common/reducer/react-provider';

const AccessLayout = lazy(() => import('components/AccessLayout/AccessLayout'));
const VerifyAccount = lazy(() => import('pages/VerifyAccount/VerifyAccount'));
const Register = lazy(() => import('pages/Register/Register'));
const Login = lazy(() => import('pages/Login/Login'));

function App() {
  return (
    <StateProvider
      initialState={initialization}
      reducer={reducer}
    >
      <BrowserRouter>
        <Suspense fallback={<p>App Loading</p>}>
          <Switch>
            <Route path="/account-confirmation" component={VerifyAccount} />
            <Route path="/register" component={Register} />
            <Route path="/" exact component={Login} />
            <Route path="/" component={AccessLayout} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </StateProvider>
  );
}

export default App;
