import { ActionDispatch } from '../../@types/apiRes';
import {
  REGISTER_USER,
  LOGIN_USER,
  LOGOUT_USER,
  REQUEST_PASSWORD_RESET,
  PASSWORD_RESET,
  VERIFY_ACCOUNT,
} from '../constant';
import auth from '../initialization/auth';

const authReducer = (state: any, action: ActionDispatch) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        login: action.payload,
      };
    case REGISTER_USER:
      return {
        ...state,
        register: action.payload,
      };
    case REQUEST_PASSWORD_RESET:
      return {
        ...state,
        requestPasswordReset: action.payload,
      };
    case PASSWORD_RESET:
      return {
        ...state,
        passwordReset: action.payload,
      };
    case VERIFY_ACCOUNT:
      return {
        ...state,
        verifiedAccount: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        ...auth,
      };
    default:
      return state;
  }
};
export default authReducer;
