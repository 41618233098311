import auth from './auth';
import media from './media';
import resources from './resources';
import countryState from './country-state';
import comment from './comment';
import program from './program';

export default {
  auth,
  media,
  resources,
  countryState,
  comment,
  program,
};
