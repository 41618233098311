import { ActionDispatch } from '../../@types/apiRes';
import {
  GET_ACTIVE_PROGRAM,
  LOGOUT_USER,
  GET_PROGRAM_BY_ID,
  GET_ACTIVE_PROGRAM_WITH_ASSOCIATE,
} from '../constant';
import program from '../initialization/program';

const countryStateReducer = (state: any, action: ActionDispatch) => {
  switch (action.type) {
    case GET_ACTIVE_PROGRAM:
      return {
        ...state,
        getActiveProgram: action.payload,
      };
    case GET_ACTIVE_PROGRAM_WITH_ASSOCIATE:
      return {
        ...state,
        getActiveProgramWithAssociate: action.payload,
      };
    case GET_PROGRAM_BY_ID:
      return {
        ...state,
        getProgramById: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        ...program,
      };
    default:
      return state;
  }
};
export default countryStateReducer;
