import { ActionDispatch } from '../../@types/apiRes';
import { FETCH_COMMENTS, LOGOUT_USER } from '../constant';
import comment from '../initialization/comment';

const commentReducer = (state: any, action: ActionDispatch) => {
  switch (action.type) {
    case FETCH_COMMENTS:
      return {
        ...state,
        fetchComments: action.payload,
      };
    case LOGOUT_USER:
      return {
        ...state,
        ...comment,
      };
    default:
      return state;
  }
};
export default commentReducer;
