/**
 * @author Odewale Ifeoluwa
 * @package withStyle module
 * @description This module provide class object to component with withStyle HOD.
 */

import React, {
  createContext, useContext, useReducer, FC, Dispatch,
} from 'react';

type ProviderProps = {
  reducer: any,
  initialState: any,
};
export const StateContext = createContext<{
  state: any,
  dispatch: Dispatch<any>
}>({ state: {}, dispatch: () => null });

export const StateProvider: FC<ProviderProps> = (
  { reducer, initialState, children }: React.PropsWithChildren<ProviderProps>,
) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);
